/*ENTHUSIAST!*/

// Variables
$purple: #8471FF;
$pink: #E0154E;
$green: #32ECB7;
$navy: #3545AC;
$orange: #FFAA33;

$sans-serif: 'Work Sans';
$signpainter: 'Knewave';


// Import webfonts 
@font-face {
    font-family: $signpainter;
    src:  url('/resources/fonts/knewave-regular.woff2') format('woff2'),
          url('/resources/fonts/knewave-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/resources/fonts/work-sans.woff') format('woff');
}

@font-face {
  font-family: $sans-serif;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/resources/fonts/work-sans-italic.woff') format('woff');
}

@font-face {
    font-family: $sans-serif;
    src: url('/resources/fonts/work-sans-bold.woff2') format('woff2'),
         url('/resources/fonts/work-sans-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


// General Styling

body {    
    background-color: $purple;
    background-image: url(/resources/icons/purple_paper.jpg);
    background-attachment: scroll;
    background-size: 740px 500px;
    background-repeat: repeat;
    color: white;
    font-family: $signpainter, sans-serif;
}

.content {
    margin: auto;
    width: 800px;
}

h1 {
    font-size: 5em;
    margin: .7em 0em;
    text-align: center;
}

h2{
    font-size: 3em;
    margin: 1em 0em .5em 0em;
}

h3 {
  background-color: rgba(255,255,255,0.1);
  padding: 50px;
  font-family: $sans-serif, sans-serif;
    font-size: 1.7em;
    line-height: 1.4;
    margin-bottom: 1em;
    list-style-type: disc;
    em {
        font-style: italic;
      }
      a {
                    text-decoration: none;
                    border-bottom: $pink 0.125em solid;
            
                    &:hover{
                        color: $navy;
                        background-color: $green;
                        border-bottom: none;
                    }
                }
}


a {
    text-decoration: none;
    color: white;

    &:hover {
        color: #ddd;
    }
}

p, ul {
    font-family: $sans-serif, sans-serif;
    font-size: 1.7em;
    line-height: 1.4;
    margin-bottom: 1em;
    list-style-type: disc;

    a {
        text-decoration: none;
        border-bottom: $pink 0.125em solid;

        &:hover{
            color: $navy;
            background-color: $green;
            border-bottom: none;
        }
    }
    
    strong {
        font-weight: 700;
        padding: 0 .3em;
    }
    
    em {
      font-style: italic;
    }

    img {
        width: 100%;
        max-width: 400px;
        height:auto;
    }
    
}


li {
    margin-bottom: .5em;

    ul {
        padding-top: .5em;
        padding-left: 2em;
        margin-bottom: 0em;
        font-size: 1em;
    }

}

.breaker {
    height: 50px;
    display: block;
}



// Navigation

.nav {
    width: 100%;
    margin: 25px 0 65px 0;
    display: block;
    font-size: 25pt;
    text-align: center;
}

.nav img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.nav_item {
    margin: 0 20px;
}


// Episode List

.episode {
    width: 100%;
    height: 325px;
    cursor: pointer;
    clear: both;
    margin-bottom: 60px;

        &:hover img {
        transform: rotate(20deg);
    }
}

#landscape {
    height: 210px;
}

.image_container {
    width: 40%;
    float: right;
}

.title_container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    float: right;
    font-weight: bold;
    font-size: 42pt;
}

#trailer{
  font-size: 36pt;
}


// Subscribe Page

.logo {
    width: 100%;
    height: 180px;
    cursor: pointer;
    clear: both;
    margin-bottom: 30px;

    img {
        margin: auto;
        height: 100%;
        width: auto;
        float: left;
        filter: drop-shadow(2px 2px 2px #555);
    }

    &:hover img{
        transform: rotate(20deg);
    }
}

.podcast_player {
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    font-size: 42pt;
    margin-left: 1em;
}


// Episode Page

.show {
    width: 100%;
    height: 325px;
}

.show_container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    float: right;
    font-weight: bold;
    font-size: 42pt;
}

.episode img {
    margin: auto;
    width: 100%;
    filter: drop-shadow(2px 2px 2px #555);
}

.show img {
    width: 100%;
    filter: drop-shadow(2px 2px 2px #555);
}

audio {
    width: 100%;
    margin: 30px 0;
}

#press_player{
  margin-top: -1em;
}


// Transcript Styling


#transcript-reveal{
    width: 100%;
    height: auto;
    padding: 20px 0px;
    background-color: $pink;
    font-size: 25pt;
    text-align: center;
    margin-top: 50px;

    &:hover{
       background-color: $green;
        color: $navy;
        cursor: pointer; 
    }

}


blockquote{
	background-color: rgba(255,255,255,0.1);
	padding: 50px;
    display: none;
    margin: none;

    strong {
        font-weight: 700;
        background-color: $orange;
        color: $navy;
        padding: 0 .3em;
    }
    
  a {
    overflow-wrap: break-word;
  }
}





// Footer Styling

footer {
    text-align: center;
    font-family: $sans-serif;
    width: 100%;
    padding: 25px 0 50px 0;
}

.copyright {
    padding-top: 40px;
}

.social {
    width: 80px;
    height: 80px;
    display: inline-block;
    margin: 0 25px;
    filter: drop-shadow(2px 2px 2px #555);

    &:hover{
        transform: rotate(20deg);
    }
}

.mobile_break {
    display: none;
}


 

// Adjust for Tablets and Narrow Windows

@media (max-width: 850px) {

    .content {
        width: 600px;
    }

    .episode{
        height: 275px;
        margin-bottom: 50px;
    }
    .show {
        height: 275px;
    }
    

    #landscape {
        height: 170px;
    }

    .image_container {
        width: 45%;
    }

    .title_container {
        width: 55%;
        font-size: 30pt;
    }

    .show_container {
        width: 55%;
        font-size: 30pt;
    }

    .podcast_player {
        font-size: 36px;
    }

    #trailer{
          font-size: 26pt;
    }

  h3 {
    padding: 25px;
  }
  blockquote{
    padding: 20px;
  }

}

.tilt {
  transform: rotate(-3deg);
}


// Adjust for Mobile Devices 


@media (max-width: 667px) {

    .subscribe_wrapper{
        width: 100%;
        max-width: 325px;
        margin: auto;
    }
    
    .subscribe_p{
        width: 100%;
        max-width: 369px;
        margin: auto;
    }
    
    ol, ul {
        list-style-position:inside;
    }
    
    .nav {
        margin: 25px 0 40px 0;
        font-size: 20pt
    }

    .nav img {
        margin-bottom: 15px;
    }

    .nav_item {
        margin: 0px 10px;
    }

    .content {
        width: 90%;
    }

    .image_container {
        width: 100%;
        float: none;
    }

    .title_container {
        width: 100%;
        padding: 20px 0 0 0;
        display: block;
        float: none;
        text-align: center;
        font-size: 30pt;
    }
  
    .show_container {
        width: 100%;
        float: none;
        display: block;
        text-align: center;
        font-size: 30pt;
        padding: 10px 0 0 0;
    }
  

    .episode img,
    .show img {
        width: 100%;
        max-width: 350px;
        margin: auto;
        display: block;
    }

    .episode:hover .sticker {
        transform: none;
    }

    .episode {
        height: auto;
    }

    .show {
        height: auto;
        margin: 0;
    }

    #landscape {
        height: auto;
    }

    audio {
        width: 100%;
    }

    .logo {
        height: 100px;
        font-size: 25pt;
    }

    .podcast_player {
        margin-left: .7em;
    }

    .mobile_break {
        display: inline;
    }
    


}

// Adjust for Narrow Mobile Devices 

@media (max-width: 340px) {

    .content {
        width: 95%;
    }

    .title_container {
        font-size: 26pt;
    }

}